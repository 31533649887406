import { NgModule } from '@angular/core';
import { LaddaComponent } from './ladda/ladda.component';
import { LaddaDirective } from './ladda/ladda.directive';

const standalone = [LaddaDirective, LaddaComponent];

@NgModule({
  imports: standalone,
  exports: standalone,
})
export class SharedLaddaModule {}
